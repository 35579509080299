import { render, staticRenderFns } from "./SuperAdminPanelView.vue?vue&type=template&id=01f52f3a"
import script from "./SuperAdminPanelView.vue?vue&type=script&setup=true&lang=js"
export * from "./SuperAdminPanelView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports